import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { TransitionGroup } from 'react-transition-group';
import type { ListItem, ListProps, UnwrappedListProps } from '..';
import Transition, { TransitionVariant } from '../../Transition/Transition';

/**
 * The `unwrapped` variant for `List`. Displays a list of items
 * without an element wrapper.
 *
 * @constructor
 *
 * @author Adam Ayres
 */
const UnwrappedList = <T extends ListItem>({
  items = [],
  children,
  listItemClassName = (): null => null,
  listItemAs: Component = 'div',
  onItemClick,
  populateReferences
}: UnwrappedListProps<T> & Omit<ListProps<T>, 'className'>): React.ReactElement => {
  const cx = useClassNameMapper();

  return (
    <>
      <TransitionGroup component={null}>
        {items.map((item, index) => (
          <Transition key={item?.id} variant={TransitionVariant.FADE}>
            <Component
              ref={
                populateReferences ? element => populateReferences(element, index, item) : undefined
              }
              className={cx(listItemClassName(item))}
              onClick={onItemClick ? () => onItemClick(item) : undefined}
            >
              {children(item)}
            </Component>
          </Transition>
        ))}
      </TransitionGroup>
    </>
  );
};

export default UnwrappedList;
